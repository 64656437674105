import { Box, Typography } from '@mui/material';
import { Formarters } from '../../../../lib/formaters';

export type ItemWithLabelAndValueProps = {
  label: string;
  value: any;
};

export default function ItemWithLabelAndValue({ label, value }: ItemWithLabelAndValueProps) {
  return (
    <Box display='flex' justifyContent='space-between' py={0.65} minWidth='350px' maxWidth='100%'>
      <Typography fontSize='15px' fontFamily='Poppins' fontWeight={400} color='#666666'>
        {Formarters.translateKeyIndicators(label)}
      </Typography>
      <Typography fontSize='15px' fontFamily='Poppins' fontWeight={700} color='#666666'>
        {Formarters.formatValuesIndicators(label, value)}
      </Typography>
    </Box>
  );
}
