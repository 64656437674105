import { Box, Typography, CircularProgress, Grid, Card, CardContent, Button } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { useJornalResults } from '../../api/jornal';
import NewsCard from './NewsCard';
import FirstNewsCard from './FirstNewsCard';
import NewsHeader, { DropdownSelectOption } from '../../ui/stories/Moleculas/NewsHeader';

const News: React.FC = () => {
  const jogoId = localStorage.getItem('jogo_id') ?? '';
  const numeroEtapa = localStorage.getItem('numero_etapa') ?? '';
  const game = localStorage.getItem('nome_jogo') ?? '';

  const currentUrl = window.location.href;
  const url = new URL(currentUrl);

  const [stage, setStage] = useState<number>(parseInt(numeroEtapa) || 0);
  const [options, setOptions] = useState<DropdownSelectOption[]>([]);

  const { data, isLoading, error, refetch: refetchJornal } = useJornalResults(jogoId, stage);

  const openNewPage = (path: string) => {
    url.pathname = `/${path}`;
    window.open(url);
  };

  useEffect(() => {
    const parsedEtapaAtual = parseInt(numeroEtapa);

    const newOptions: DropdownSelectOption[] = [];

    for (let i = 2; i <= parsedEtapaAtual; i++) {
      newOptions.push({ label: `Etapa ${i}`, value: i.toString() });
    }

    setOptions(newOptions);
  }, [numeroEtapa]);

  const onChangeStage = async (value: number) => {
    setStage(value);

    await refetchJornal;
  };

  const StyledButton = styled(Button)`
    background-color: #00ab4a;
    border-radius: 15px;
    font-family: 'Poppins';
    font-size: 15px;
    font-weight: 600;
    width: 225px;
    color: #fff;
    padding: 8px 0;
    text-transform: none;

    &:hover {
      background-color: #007b36;
    }
  `;

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress color='success' size={80} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display='flex' flexDirection='column' height='100%' width='100%' alignItems='center' pb={4}>
        <NewsHeader hasSelect={false} gameEtapa={numeroEtapa} gameName={game} />
        <Typography
          sx={{ padding: '15px', textAlign: 'center' }}
          color='#6666'
          fontSize='clamp(1rem, 2.5vw, 1.5rem)'
          fontFamily='Poppins'
          fontWeight={400}
          lineHeight='43px'
        >
          Não foi possível acessar as notícias dessa etapa, tente novamente.
        </Typography>
      </Box>
    );
  }

  return (
    <Box display='flex' flexDirection='column' height='100%' width='100%' alignItems='center' pb={4}>
      {/* Header */}
      <NewsHeader hasSelect gameEtapa={data && data[0]?.numero_etapa} gameName={game} onUpdateState={onChangeStage} options={options} />

      {/* primeira noticia e informações dos indicadores */}
      <Box width='85%' pt={4}>
        <Grid container pb={10} spacing={3}>
          <Grid item xs={12} sm={12} md={7} lg={7.5} xl={8.5}>
            {data?.length ? <FirstNewsCard news={data[0]} onClick={() => openNewPage(`news/${data[0].id}`)} /> : null}
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={4.5} xl={3.5}>
            <Card
              sx={{
                height: '175px',
                borderRadius: '13px',
                backgroundImage: 'url(/indicators_card_1.jfif)',
                backgroundSize: 'cover',
                backgroundPosition: '0% 30%;',
                marginBottom: 3,
              }}
            >
              <CardContent
                sx={{
                  height: '85%',
                  display: 'flex',
                  alignItems: 'flex-end',
                }}
              >
                <Typography
                  color='#fff'
                  fontSize='clamp(0.85rem, 2.5vw, 1rem)'
                  fontFamily='Poppins'
                  fontWeight={900}
                  sx={{
                    textShadow: '0px 2px 4px #00000099',
                  }}
                >
                  Os indicadores são informações essenciais para o sucesso de uma empresa na etapa. <br /> Para visualizar, clique no botão
                  a baixo.
                </Typography>
              </CardContent>
            </Card>

            <Card
              sx={{
                height: '175px',
                borderRadius: '13px',
                backgroundImage: 'url(/indicators_card_2.jfif)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <CardContent
                sx={{
                  height: '85%',
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'center',
                }}
              >
                <StyledButton onClick={() => openNewPage('indicators')} variant='contained'>
                  Acessar Indicadores
                </StyledButton>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Lista de noticias */}

        {data?.slice(1).map((news, index) => (
          <NewsCard key={index} news={news} onClick={() => openNewPage(`news/${news.id}`)} />
        ))}
      </Box>
    </Box>
  );
};

export default News;
