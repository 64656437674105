import React, { useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, useMediaQuery } from '@mui/material';
import { QueryClient } from 'react-query';
import { ArrowButton, EndPhaseModal, ErrorModal, SuccessModal } from './ui/index';
import { BottomContainer, TopContainer, LeftContainer, RightContainer, MidContainer } from './pages/MainPage';
import { objectHasChanges, scaleValue, transformToNestedObject } from './lib/utils';
import { useGameDetails, useGameResults, useSendGameResults } from './api/game';
import { FolhaDecisaoDTO, JornalDTO, Ranking } from './dto';
import { fetchJornalResults, useJornalResults } from './api/jornal';
import { replaceObjWithChanges } from './lib/replaceObjWIthChanges';
import { useEtapaResults } from './api/etapa';
import { EtapaDTO } from './dto/etapaDTO';
import { GameDetailsDTO } from './dto/gameDetailsDTO';
import { useRelatorioInitialData } from './api/relatorio';

interface Error {
  msg: string;
  param: string;
  location: string;
}

function App({ queryClient }: { queryClient: QueryClient }) {
  const [showTopContainer, setShowTopContainer] = useState(true);
  const [showRightContainer, setShowRightContainer] = useState(true);
  const ultraMinDesktopSize = useMediaQuery('(min-width: 1020px)');
  const minDesktopSize = useMediaQuery('(min-width: 1200px)');
  const mediumDesktopSize = useMediaQuery('(min-width: 1440px)');
  const largeDesktopSize = useMediaQuery('(min-width: 1920px)');

  const [midContainerSize, setMidContainerSize] = useState({ width: 0, height: 0 });
  const midContainerRef = useRef<HTMLDivElement>(null); // Ref para capturar o contêiner do grid
  const midContainerDataRef = useRef<any>({
    current: {},
  });

  const [showModalError, setShowModalError] = useState(false);
  const [sendButtonActive, setSendButtonActive] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [dataJornal, setDataJornal] = useState<JornalDTO[] | null>(null);
  const [isNextStepButtonPressed, setIsNextStepButtonPressed] = useState(false);
  const [showNextStepUnavailable, setShowNextStepUnavailable] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(0);

  const jogoId = localStorage.getItem('jogo_id') ?? '';
  const { data: dataGameDetails, isLoading: isLoadingDataGameDetails, refetch: refetchGameDetails } = useGameDetails(jogoId);
  const { data: dataGame, isLoading: isLoadingDataGame, error: errorGameResults, refetch: refetchGameResults } = useGameResults(jogoId);
  const { data: dataEtapa, isLoading: isLoadingEtapa, error: errorEtapa, refetch: refetchEtapa } = useEtapaResults(jogoId);
  const {
    data: dataInitialDataPDF,
    isLoading: isLoadingInitialDataPDF,
    error: errorInitialDataPDF,
    refetch: refetchInitialDataPDF,
  } = useRelatorioInitialData(jogoId);
  const { data: dataSent, isLoading: isLoadingSent, mutateAsync, isSuccess, isError, error: errorApi } = useSendGameResults(jogoId);

  useEffect(() => {
    setForceUpdate((prev) => prev + 1); // Força a re-renderização
  }, [dataInitialDataPDF]);

  useEffect(() => {
    if (errorApi) {
      setShowModalError(isError);
    }
    if (isSuccess) {
      setShowModalSuccess(true);
      setSendButtonActive(false);
    }
  }, [isError, errorApi, isSuccess]);

  // Verifica se os dados de etapa estão prontos e chama o useJornalResults apenas após isso
  useEffect(() => {
    let isNewEtapa = false;
    if (dataEtapa && dataEtapa.numero_etapa > parseInt(localStorage.getItem('numero_etapa') ?? '', 10)) {
      const queryParams = new URLSearchParams(window.location.search);
      const token = queryParams.get('token');
      const jogo_id = queryParams.get('jogo_id');
      const id_empresa = queryParams.get('id_empresa');
      const id_etapa = dataEtapa.id.toString();

      localStorage.setItem('id_etapa', id_etapa);
      localStorage.setItem('numero_etapa', dataEtapa.numero_etapa.toString());
      // refetchGameDetails()
      // refetchGameResults()

      // // Invalida queries relacionadas ao jogo para garantir que refaça o fetch
      // queryClient.invalidateQueries(['gameResults', jogoId])
      // queryClient.invalidateQueries(['gameDetails', jogoId])

      isNewEtapa = true;
      setIsNextStepButtonPressed(false);
      window.location.href = `/mainpage?token=${token}&jogo_id=${jogo_id}&id_etapa=${id_etapa}&id_empresa=${id_empresa}`;
    } else if (isNextStepButtonPressed && dataEtapa && dataEtapa.numero_etapa) {
      setShowNextStepUnavailable(true);
      setIsNextStepButtonPressed(false);
    }

    if (dataEtapa && !isLoadingEtapa) {
      localStorage.setItem('nome_jogo', dataEtapa.nome_jogo);
      localStorage.setItem('resultados_liberados', dataEtapa.resultados_liberados.toString());
      localStorage.setItem('ignoreBeforeUnload', 'false');

      if (isNewEtapa || dataJornal === null) {
        fetchJornalResults(jogoId, dataEtapa.numero_etapa)
          .then((res) => {
            localStorage.setItem('numero_etapa', dataEtapa.numero_etapa.toString());
            setDataJornal(res); // Define os dados do Jornal no estado
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  }, [dataEtapa, isLoadingEtapa, jogoId, dataJornal, refetchGameDetails, refetchGameResults, isNextStepButtonPressed]);

  useEffect(() => {
    // Atualiza as dimensões do MidContainer quando o componente é montado ou quando o layout muda
    const updateMidContainerSize = () => {
      if (midContainerRef.current) {
        const { offsetWidth, offsetHeight } = midContainerRef.current;
        setMidContainerSize({
          width: offsetWidth,
          height: offsetHeight,
        });
      }
    };

    updateMidContainerSize(); // Atualiza ao montar

    window.addEventListener('resize', updateMidContainerSize); // Adiciona listener para redimensionamento da tela
    return () => {
      window.removeEventListener('resize', updateMidContainerSize); // Remove o listener quando o componente é desmontado
    };
  }, [showTopContainer, showRightContainer, dataGame]); // Recalcula quando containers visíveis mudam

  useEffect(() => {
    // Função chamada antes da página ser descarregada (fechada ou recarregada)
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      // Verifica se o botão de envio está ativo
      if (sendButtonActive && localStorage.getItem('ignoreBeforeUnload') === 'false') {
        // Impede o fechamento da página ao exibir um aviso para o usuário
        event.preventDefault();
        // Algumas versões de navegadores mais antigos exigem que esta linha seja explicitamente definida
        event.returnValue = 'Você tem certeza que deseja sair da página?';
      }
    };

    // Adiciona o evento 'beforeunload' à janela para capturar a tentativa de fechar/recarregar a página
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup: remove o evento quando o componente for desmontado
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [sendButtonActive]);

  // Renderiza um "Loading" enquanto o login está sendo feito
  if (isLoadingEtapa || isLoadingDataGameDetails || isLoadingDataGame) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', // Preenche toda a altura da viewport
        }}
      >
        <CircularProgress color='success' size={80} />
      </Box>
    );
  }

  if (errorGameResults)
    return (
      <Box pt={3} display='grid' textAlign='center' justifyContent='center'>
        Error ao carregar a simulação
        <Box
          mt={3}
          onClick={() => window.open(process.env.REACT_APP_VUE_WEBSITE_URL, '_self')}
          color='#666666'
          padding='12px 7px'
          width='250px'
          bgcolor='#F3F3F3'
          borderRadius='12px'
          textAlign='center'
          sx={{ fontSize: '15px', fontFamily: 'Poppins', fontWeight: 500, cursor: 'pointer' }}
        >
          Acessar minhas simulações
        </Box>
      </Box>
    );

  // Função para definir as áreas do grid dinamicamente com base na visibilidade dos containers
  const getGridTemplateAreas = () => {
    if (!showTopContainer && !showRightContainer) {
      return `
        "left mid mid mid"
        "left mid mid mid"
        "left bottom bottom bottom"
      `;
    }
    if (!showTopContainer && showRightContainer) {
      return `
        "left mid mid right"
        "left mid mid right"
        "left bottom bottom bottom"
      `;
    }
    if (showTopContainer && !showRightContainer) {
      return `
        "left top top top"
        "left mid mid mid"
        "left bottom bottom bottom"
      `;
    }
    return `
      "left top top top"
      "left mid mid right"
      "left bottom bottom bottom"
    `;
  };

  let scale = 1;
  if (ultraMinDesktopSize) {
    scale = 0.7;
  }
  if (minDesktopSize) {
    scale = 0.75;
  }
  if (mediumDesktopSize) {
    scale = 0.85;
  }
  if (largeDesktopSize) {
    scale = 1;
  }

  let debounceTimeout: NodeJS.Timeout | null = null;

  const handleMidContainerChange = (newData: any) => {
    midContainerDataRef.current = transformToNestedObject({
      ...midContainerDataRef.current,
      ...newData,
    }); // Atualiza a ref sem re-renderizar

    if (midContainerDataRef.current && objectHasChanges(dataGame, midContainerDataRef.current)) {
      setSendButtonActive(true);
    } else {
      setSendButtonActive(false);
    }

    // Limpa o timeout anterior
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    // Define um novo timeout para atualizar o sessionStorage após 500ms (por exemplo)
    debounceTimeout = setTimeout(() => {
      sessionStorage.setItem('midContainerData', JSON.stringify(midContainerDataRef.current));

      if (newData) {
        let canForceUpdate = false;
        const keys = [
          'compras.A.prazo_pagamento',
          'compras.B.prazo_pagamento',
          'compras.C.prazo_pagamento',
          'compras.D.prazo_pagamento',
          'compras.E.prazo_pagamento',
          'vendas.A.condicao_recebimento',
          'vendas.B.condicao_recebimento',
          'vendas.C.condicao_recebimento',
          'vendas.D.condicao_recebimento',
          'vendas.E.condicao_recebimento',
          'marketing.A.tv',
          'marketing.A.midias_sociais',
          'marketing.A.radio',
          'marketing.A.impressos',
          'marketing.B.tv',
          'marketing.B.midias_sociais',
          'marketing.B.radio',
          'marketing.B.impressos',
          'marketing.C.tv',
          'marketing.C.midias_sociais',
          'marketing.C.radio',
          'marketing.C.impressos',
          'marketing.D.tv',
          'marketing.D.midias_sociais',
          'marketing.D.radio',
          'marketing.D.impressos',
          'marketing.E.tv',
          'marketing.E.midias_sociais',
          'marketing.E.radio',
          'marketing.E.impressos',
          'financas.aplicacao_2.prazo',
          'financas.emprestimo_normal.prazo',
        ];
        if (keys.some((key) => key in newData)) {
          canForceUpdate = true;
        }

        if (canForceUpdate) {
          setForceUpdate((prev) => prev + 1); // Força a re-renderização
        }
      }
    }, 150); // 150ms de debounce, ajuste conforme necessário
  };

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateAreas: getGridTemplateAreas(), // Áreas do grid adaptadas à visibilidade
        gridTemplateColumns: `${scaleValue(scale, '302px', 0.3)} 1fr 1fr ${showRightContainer ? scaleValue(scale, '262px', 0.3) : '0px'}`,
        gridTemplateRows: `${showTopContainer ? scaleValue(scale, '195px', 1) : '0px'} 1fr 71px`,
        gap: '0px',
        height: '100vh',
        position: 'relative',
        background: 'rgba(245, 245, 245, 1)',
      }}
    >
      {/* Success modal para mostrar dados de sucesso vindo no submit do MidContainer para api */}
      {isSuccess && (
        <SuccessModal
          modalIsOpen={showModalSuccess}
          onClose={() => setShowModalSuccess(false)}
          successMessage='Dados enviados com sucesso!'
        />
      )}

      {/* Error modal para mostrar dados de erros vindo no submit do MidContainer para api */}
      {isError && errorApi && (
        <ErrorModal
          modalIsOpen={showModalError}
          onClose={() => setShowModalError(false)}
          errorMessage={JSON.parse(errorApi.message).message}
          errors={JSON.parse(errorApi.message).errors as Error[]}
          errorCode={JSON.parse(errorApi.message).statusCode}
        />
      )}

      {showNextStepUnavailable && (
        <EndPhaseModal
          modalIsOpen={showNextStepUnavailable}
          onClose={() => setShowNextStepUnavailable(false)}
          message='Próxima etapa indisponível, ou ainda não liberada.'
        />
      )}

      {/* Left Container */}
      <Box
        sx={{
          gridArea: 'left',
          width: scaleValue(scale, '302px', 0.3),
          height: '100%',
          position: 'fixed',
          zIndex: 5,
          overflowY: 'overlay',
          overflowX: 'hidden',
          background: 'white',
        }}
      >
        <LeftContainer
          sendButtonActive={sendButtonActive}
          gameDetails={dataGameDetails as GameDetailsDTO}
          ranking={dataGame?.ranking as Ranking[]}
          pdfData={dataInitialDataPDF}
          boxStyle={{
            boxSizing: 'border-box',
            width: '101%',
            height: '100%',
            borderRadius: 0,
            position: 'relative',
            zIndex: 4,
            borderBottom: 'none',
          }}
        />
      </Box>

      {/* Top Container */}
      {showTopContainer && (
        <Box
          sx={{
            gridArea: 'top',
            position: 'absolute', // Sobreposto ao MidContainer
            top: 0,
            left: 0,
            right: 0,
            height: scaleValue(scale, '195px', 1),
            zIndex: 2,
          }}
        >
          <TopContainer
            folhaDecisaoData={dataGame as FolhaDecisaoDTO}
            boxStyle={{
              boxSizing: 'border-box',
              width: '100%',
              height: '100%',
              borderRadius: 0,
              alignItems: 'center',
            }}
            scale={0.5}
            onClickArrow={() => setShowTopContainer(false)}
          />
        </Box>
      )}

      {/* Right Container */}
      {showRightContainer && dataJornal && (
        <Box
          sx={{
            gridArea: 'right',
            position: 'absolute', // Sobreposto ao MidContainer
            top: `${false ? '195px' : '0px'}`, // Ajusta a posição com base no TopContainer
            right: 0,
            bottom: 0,
            width: scaleValue(scale, '262px', 1),
            zIndex: 1,
          }}
        >
          <RightContainer
            jornais={dataJornal as JornalDTO[]}
            boxStyle={{
              boxSizing: 'border-box',
              width: '100%',
              height: '100%',
              borderRadius: 0,
            }}
            onClickArrow={() => setShowRightContainer(false)}
          />
        </Box>
      )}

      {/* Mid Container (não se ajusta, permanece fixo) */}
      <Box
        ref={midContainerRef} // Aplica a referência ao contêiner
        sx={{
          gridArea: 'mid',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          zIndex: 0, // Fica atrás dos containers sobrepostos
        }}
      >
        <MidContainer
          onChange={handleMidContainerChange}
          folhaDecisaoData={dataGame as FolhaDecisaoDTO}
          dataEtapa={dataEtapa as EtapaDTO}
          gameDetailsDTO={dataGameDetails as GameDetailsDTO}
          scale={scale}
          boxStyle={{
            position: 'fixed',
            width: midContainerSize.width, // Ajusta a largura
            height: midContainerSize.height, // Ajusta a altura
          }}
        />
      </Box>

      {/* Bottom Container */}
      <Box sx={{ gridArea: 'bottom' }}>
        <BottomContainer
          etapa={dataEtapa as EtapaDTO}
          sendButtonActive={sendButtonActive}
          // sendButtonActive={!!midContainerDataRef.current}
          sendFunction={async () => {
            if (midContainerDataRef.current) {
              const newObj = replaceObjWithChanges(dataGame, midContainerDataRef.current);
              try {
                const objToSend = {
                  compras: newObj?.compras,
                  financas: newObj?.financas,
                  vendas: newObj?.vendas,
                  rh: newObj?.rh,
                  marketing: newObj?.marketing,
                  contas_pagar: newObj?.contas_pagar,
                };
                const searchCustPriceByPaymentTerm = (key: string) => {
                  if (objToSend.compras[key]?.prazo_pagamento === 1) {
                    return (dataEtapa!.parametros_custo as Record<any, any>)[key]?.valor_etapa;
                  }
                  if (objToSend.compras[key]?.prazo_pagamento === 2) {
                    return (dataEtapa!.parametros_custo as Record<any, any>)[key]?.valor_etapa_2parcelas;
                  }
                  if (objToSend.compras[key]?.prazo_pagamento === 3) {
                    return (dataEtapa!.parametros_custo as Record<any, any>)[key]?.valor_etapa_3parcelas;
                  }
                  if (objToSend.compras[key]?.prazo_pagamento === 20) {
                    return (dataEtapa!.parametros_custo as Record<any, any>)[key]?.valor_etapa_2parcelas_sem_entrada;
                  }
                  if (objToSend.compras[key]?.prazo_pagamento === 30) {
                    return (dataEtapa!.parametros_custo as Record<any, any>)[key]?.valor_etapa_3parcelas_sem_entrada;
                  }
                  return 0;
                };
                for (const key in objToSend.compras) {
                  objToSend.compras[key].preco_custo = searchCustPriceByPaymentTerm(key);
                }
                await mutateAsync(objToSend);
              } catch (error) {
                // ...
              }
            }
          }}
          nextStepFunction={async () => {
            try {
              await refetchEtapa();
              setIsNextStepButtonPressed(true);
            } catch (error) {
              // ...
            }
          }}
          boxStyle={{
            boxSizing: 'border-box',
            width: '100%',
            height: '100%',
            borderRadius: 0,
            position: 'relative',
            zIndex: 2,
          }}
        />
      </Box>

      {/* Botões para mostrar novamente os containers ocultos */}
      {!showTopContainer && (
        <ArrowButton
          direction='down'
          buttonStyle={{ position: 'absolute', top: '0%', left: '53%' }}
          onClick={() => setShowTopContainer(true)}
        />
      )}
      {!showRightContainer && (
        <ArrowButton
          direction='left'
          buttonStyle={{ position: 'absolute', top: '51%', right: '0%' }}
          onClick={() => setShowRightContainer(true)}
        />
      )}
    </Box>
  );
}

export default App;
