import { Box, Typography } from '@mui/material';
import OutlinedCard from '../../../ui/stories/Atomos/OutlinedCard';

export type FirstNewsCardProps = {
  news: any;
  onClick: () => void;
};

export default function FirstNewsCard({ news, onClick }: FirstNewsCardProps): JSX.Element {
  return (
    <OutlinedCard
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        display: 'grid',
        alignItems: 'center',
        transition: 'background-color 0.3s',
        '&:hover': {
          backgroundColor: '#f0f0f0',
        },
      }}
    >
      <Box sx={{ padding: '35px 32px' }}>
        <Typography
          color='#00AB4A'
          fontSize='clamp(2.5rem, 2.5vw, 4rem)'
          fontFamily='Poppins'
          fontWeight={700}
          lineHeight='50px'
          sx={{ paddingBottom: '10px' }}
        >
          {news.materia_titulo}
        </Typography>
        <div
          dangerouslySetInnerHTML={{
            __html: news.materia_texto.length > 207 ? `${news.materia_texto.slice(0, 207)}...` : news.materia_texto || '',
          }}
        />
        <Typography
          fontSize='15px'
          fontFamily='Poppins'
          fontWeight={400}
          sx={{ paddingTop: '10px', paddingBottom: '20px' }}
          color='#666666'
        >
          Etapa {news.numero_etapa}
        </Typography>
      </Box>
    </OutlinedCard>
  );
}
