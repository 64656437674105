import { Box, Typography } from '@mui/material';
import { ItemWithLabelAndValue, OutlinedCard } from '../../Atomos';

export type OutlinedCardWhitValuesProps = {
  title: string;
  values: Record<string, unknown>;
};

export default function OutlinedCardWhitValues({ title, values }: OutlinedCardWhitValuesProps) {
  return (
    <OutlinedCard>
      <Box sx={{ padding: '35px 32px' }}>
        <Typography fontSize='clamp(1.5rem, 2.5vw, 2rem)' fontFamily='Poppins' fontWeight={700}>
          {title}
        </Typography>

        {Object.entries(values).map(([key, value]) => (
          <ItemWithLabelAndValue key={key} label={key} value={value} />
        ))}
      </Box>
    </OutlinedCard>
  );
}
