import { Card, CardProps } from '@mui/material';
import React from 'react';

export type OutlinedCardType = {
  children?: React.ReactNode;
  sx?: React.CSSProperties;
};

export type OutlinedCardProps = CardProps & OutlinedCardType;

const style: React.CSSProperties = {
  width: '100%',
  height: '100%',
  backgroundColor: '#FAFAFA',
  borderRadius: '8px',
};

export default function OutlinedCard({ sx, children, ...props }: OutlinedCardProps) {
  return (
    <Card variant='outlined' sx={{ ...style, ...sx }} {...props}>
      {children}
    </Card>
  );
}
