import { Box, Typography, CircularProgress, Grid } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useJornalByIdResults } from '../../api/jornal';
import NewsHeader from '../../ui/stories/Moleculas/NewsHeader';
import OutlinedCard from '../../ui/stories/Atomos/OutlinedCard';

const NewsDetail: React.FC = () => {
  const jogoId = localStorage.getItem('jogo_id') ?? '';
  const numeroEtapa = localStorage.getItem('numero_etapa') ?? '';
  const game = localStorage.getItem('nome_jogo') ?? '';

  const { id } = useParams();
  const { data, isLoading, error } = useJornalByIdResults(jogoId, id);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress color='success' size={80} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display='flex' flexDirection='column' height='100%' width='100%' alignItems='center' pb={4}>
        <NewsHeader hasSelect={false} gameEtapa={numeroEtapa} gameName={game} />
        <Typography
          sx={{ padding: '15px', textAlign: 'center' }}
          color='#6666'
          fontSize='clamp(1rem, 2.5vw, 1.5rem)'
          fontFamily='Poppins'
          fontWeight={400}
          lineHeight='43px'
        >
          Não foi possível acessar a matéria deseja, tente novamente.
        </Typography>
      </Box>
    );
  }

  return (
    <Box display='flex' flexDirection='column' height='100%' width='100%' alignItems='center' pb={4}>
      {/* Header */}
      <NewsHeader hasSelect={false} gameEtapa={data?.numero_etapa} gameName={game} />

      <Box width='85%' pt={4}>
        <OutlinedCard>
          <Box sx={{ padding: '35px 32px' }}>
            <Grid container justifyContent='space-between' alignItems='center'>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={5} px={0.5}>
                <Typography
                  color='#00AB4A'
                  fontSize='clamp(2.5rem, 2.5vw, 4rem)'
                  fontFamily='Poppins'
                  fontWeight={700}
                  lineHeight='50px'
                  sx={{ paddingBottom: '10px' }}
                >
                  {data?.materia_titulo}
                </Typography>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      data?.materia_texto && data.materia_texto.length > 207
                        ? `${data.materia_texto.slice(0, 207)}...`
                        : data?.materia_texto || '',
                  }}
                />
                <Typography
                  fontSize='15px'
                  fontFamily='Poppins'
                  fontWeight={400}
                  sx={{ paddingTop: '10px', paddingBottom: '20px' }}
                  color='#666666'
                >
                  Etapa {data?.numero_etapa}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={5} px={0.5}>
                <img style={{ borderRadius: '15px', width: '100%' }} src={data?.url_imagem_destaque} alt='Imagem noticia' />
              </Grid>
            </Grid>
          </Box>
        </OutlinedCard>
      </Box>

      <Box width='85%' pt={2}>
        <div dangerouslySetInnerHTML={{ __html: data ? data.materia_texto : '' }} />
      </Box>
    </Box>
  );
};

export default NewsDetail;
