/* eslint-disable no-unsafe-optional-chaining */
import { Box, InputAdornment, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Input1 from '../../Atomos/Input1';
import { HelpTooltip } from '../../Atomos/HelpTooltip';
import { Formarters, scaleValue } from '../../../utils'; // Importando scaleValue

export type InputWithTitleProps = {
  title: string;
  tooltipText: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, error: string | null) => void;
  defaultValue?: string;
  initialAdornment?: string;
  scale?: number; // Adicionando a prop de escala
  disabled?: boolean;
  validator?: (value: string) => string | null; // Função de validação
  helperText?: string; // Texto de ajuda
  onlyPositiveNumber?: boolean;
};

const textStyle: React.CSSProperties = {
  color: 'black',
  fontSize: 22,
  fontFamily: 'Poppins',
  fontWeight: '700',
  letterSpacing: 0.15,
  wordWrap: 'break-word',
};

export default function InputWithTitle2({
  title,
  tooltipText,
  onlyPositiveNumber = true,
  initialAdornment,
  onChange,
  defaultValue,
  disabled,
  helperText,
  validator,
  scale = 1,
}: InputWithTitleProps) {
  const [error, setError] = useState<string | null>(null); // Estado para o erro
  const [actualValue, setActualValue] = useState<string | undefined>(undefined); // Estado para o valor atual

  useEffect(() => {
    const isError = validator?.(actualValue || defaultValue || '');
    if (isError) setError(isError);
    else if (error !== null) setError(null);
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace('.', ',');
    const isError = false;
    let validationError: string | null = null;

    // Executa a função de validação, se houver
    if (validator) {
      validationError = validator(value);
      setError(validationError); // Atualiza o estado do erro
    }

    if (onlyPositiveNumber) {
      let canUpdateValue = true;
      const normalizedValue = Formarters.parseNumberWithComma(value);
      if (!(normalizedValue >= 0)) canUpdateValue = false;
      if (isError) canUpdateValue = false;
      if (value === 'NaN') canUpdateValue = false;

      if (canUpdateValue || value === '') {
        setActualValue(Formarters.maskMoneyUnlimited(value));
        onChange?.(event, validationError);
      }
    } else {
      setActualValue(Formarters.maskMoneyUnlimited(value));
      onChange?.(event, validationError);
    }
  };

  return (
    <Box
      display='flex'
      alignItems='center'
      width='100%'
      justifyContent='space-between'
      sx={{ gap: scaleValue(scale, '4px') }} // Aplicando scale no gap
    >
      <Box
        display='flex'
        gap={scaleValue(scale, '4px')} // Aplicando scale no gap
        alignItems='center'
        flexWrap='wrap'
      >
        <Typography
          style={{
            ...textStyle,
            fontSize: scaleValue(scale, textStyle.fontSize as number), // Aplicando scale no tamanho da fonte
          }}
        >
          {title}
        </Typography>
        <HelpTooltip tooltipText={tooltipText} size={scaleValue(scale, 18) as number} /> {/* Aplicando scale no tamanho da tooltip */}
      </Box>
      <Input1
        defaultValue={defaultValue}
        onChange={handleInputChange}
        disabled={disabled}
        value={actualValue}
        sx={{ marginTop: scaleValue(scale, '4px'), width: '251.68px' }} // Aplicando scale na margem superior
        error={!!error} // Se houver erro, ativa o estado de erro no Input1
        helperText={error || helperText} // Mostra o erro ou o texto de ajuda
        isControlledComponent
        InputProps={{
          startAdornment: <InputAdornment position='start'>{initialAdornment}</InputAdornment>,
        }}
      />
    </Box>
  );
}
